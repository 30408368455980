:root {
  --primary-color: #1b262c;
  --secondary-color: #0f4c75;
  --tertiary-color: #3282b8;
  --quaternary-color: #f2e9e4;
  --white: #ffffff;
  --black: #010101;
  --font: Hammersmith One;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}
* {
  margin: 0;
  padding: 0;
  font-family: var(--font);
}
a {
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  color: var(--tertiary-color);
  text-decoration: none;
}

a:active {
  text-decoration: none;
  color: var(--primary-color);
}

h1 {
  font-size: 6rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
}
p {
  font-size: 20px;
}

form {
  color: var(--primary-color);
}
a.active {
  color: var(--secondary-color);
}
a.pending {
  cursor: wait;
}
